import { NAME } from '@/app/campaigns/constants';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getWorkspaceById } from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';
import { CapabilitiesTarget, useUserCapabilities } from '@/utils/hooks/useUserCapabilities';

import Template from './Template';
import { templates } from './templates';

export interface Props {
    workspaceId: string;
}

const EmptyState = ({ workspaceId }: Props) => {
    const workspace = useAppSelector((state) => getWorkspaceById(state, workspaceId));
    const { t } = useTranslation(NAME);
    const hasCampaigns = workspace?.relationships?.campaigns?.data?.length > 1;
    const { canCreate } = useUserCapabilities(CapabilitiesTarget.Campaign);

    if (hasCampaigns || !canCreate) {
        return (
            <div className="mx-auto my-14 text-center sm:mt-24">
                <p className="text-3xl font-medium text-gray-500">{t('no-funnels-found')}</p>
                {canCreate && (
                    <p className="mt-4 leading-relaxed text-gray-400">
                        {t('no-funnels-found-description')}
                    </p>
                )}
            </div>
        );
    }

    return (
        <div className="mx-auto mt-6 md:mt-10 lg:mt-14">
            <p className="mt-2 w-full text-sm text-gray-500 sm:w-1/2 md:text-base">
                {t('funnel-overview-empty')}
            </p>
            <ul
                role="list"
                className="mt-6 grid grid-cols-1 gap-6 border-b border-t py-6 sm:grid-cols-2"
            >
                {templates.map((template) => (
                    <Template key={template.id} template={template} />
                ))}
            </ul>
            <div className="mt-4 flex pb-4">
                <Link href="/new/blank">
                    <div className="text-sm font-semibold text-blue-500 hover:text-blue-600">
                        {t('start-blank')}
                        <span aria-hidden="true"> &rarr;</span>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default EmptyState;
