import { NAME, V3_SHARING_WARNING_BANNER_KEY } from '@/app/campaigns/constants';

import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getCampaignTrackingVersion } from '@/app/campaigns/helpers';
import { getCampaignsAsArray, getDidFetchCampaigns } from '@/app/campaigns/models/campaigns';
import { getFilter } from '@/app/campaigns/models/overview';
import { CampaignFilter } from '@/app/campaigns/types';
import { getDefaultWorkspace } from '@/app/workspaces/models/workspaces';
import { useAppSelector } from '@/core/redux/hooks';
import { CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';
import { useUserCapabilities } from '@/utils/hooks/useUserCapabilities';
import { initEmojiMartData } from '@/utils/initEmojiMartData';
import { reportError } from '@/utils/sentry';

initEmojiMartData();

interface Props {
    workspaceId: string;
}

const V3SharingWarningBanner = ({ workspaceId }: Props) => {
    const { t } = useTranslation(NAME);

    const [showBanner, setShowBanner] = useState(false);

    const filter = useAppSelector(getFilter);
    const campaigns = useAppSelector(getCampaignsAsArray);
    const hasLoaded = useAppSelector(getDidFetchCampaigns);
    const defaultWorkspaceId = useAppSelector(getDefaultWorkspace)?.id;
    const isDefaultWorkspace = workspaceId === defaultWorkspaceId;

    const capabilities = useUserCapabilities(CapabilitiesTarget.Workspaces);
    const hasPermission = capabilities.canCreate;

    const filterAllSelected = filter === CampaignFilter.all;
    const hasV3Funnels = useMemo(
        () => campaigns.some((campaign) => getCampaignTrackingVersion(campaign) === 'v3'),
        [campaigns],
    );

    const getClosedBanner = useCallback(() => {
        try {
            // Check if either LS or SS has the key
            const hasSeenBannerLS = localStorage.getItem(V3_SHARING_WARNING_BANNER_KEY) === 'true';
            const hasSeenBannerSS =
                sessionStorage.getItem(V3_SHARING_WARNING_BANNER_KEY) === 'true';

            return hasSeenBannerLS || hasSeenBannerSS;
        } catch (error) {
            reportError({
                error,
                source: 'runtime',
            });

            return null;
        }
    }, []);

    const handleCloseBanner = useCallback((permanent?: boolean) => {
        const storageTarget = permanent ? localStorage : sessionStorage;

        try {
            storageTarget.setItem(V3_SHARING_WARNING_BANNER_KEY, 'true');
        } catch (error) {
            reportError({
                error,
                source: 'runtime',
            });
        } finally {
            setShowBanner(false);
        }
    }, []);

    const closePermanently = useCallback(() => {
        handleCloseBanner(true);
    }, [handleCloseBanner]);

    const closeBanner = useCallback(() => {
        handleCloseBanner();
    }, [handleCloseBanner]);

    useEffect(() => {
        setShowBanner(!getClosedBanner());
    }, [getClosedBanner]);

    if (
        !hasPermission ||
        !showBanner ||
        !hasLoaded ||
        isDefaultWorkspace ||
        !filterAllSelected ||
        !hasV3Funnels
    ) {
        return null;
    }

    return (
        <div className="pointer-events-none fixed inset-x-8 bottom-16 z-50 flex">
            <div className="pointer-events-auto mx-auto flex w-full max-w-3xl flex-col overflow-hidden rounded-xl border bg-white text-sm shadow-2xl shadow-gray-200 md:flex-row">
                <div className="hidden items-center self-center px-5 py-4 text-2xl md:flex">
                    {/* @ts-ignore */}
                    <em-emoji id="wave" size="24px" />
                </div>

                <div className="flex flex-1 flex-col justify-center py-4 pl-3 pr-3 md:pl-0">
                    <div className="mb-2 flex items-center gap-2 text-sm font-bold leading-6 text-gray-700">
                        <div className="flex text-2xl md:hidden">
                            {/* @ts-ignore */}
                            <em-emoji id="wave" size="20px" />
                        </div>

                        {t('v3-sharing-warning-banner-title')}
                    </div>
                    <div className="leading-6 text-gray-500">
                        {t('v3-sharing-warning-banner-description')}
                    </div>
                </div>
                <div className="flex h-full flex-col md:border-l md:border-t-0">
                    <button
                        className="flex h-1/2 flex-row items-center justify-center border-t px-12 py-3 text-gray-700 transition hover:bg-gray-100 md:border-t-0"
                        onClick={closePermanently}
                    >
                        {t('common:do-not-show-again')}
                    </button>
                    <button
                        className="flex h-1/2 flex-row items-center justify-center border-t px-12 py-3 text-gray-700 transition hover:bg-gray-100"
                        onClick={closeBanner}
                    >
                        {t('common:close')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default memo(V3SharingWarningBanner);
