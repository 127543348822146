import { ENDPOINTS, MUTATION_KEYS, QUERY_KEYS } from '@/app/campaigns/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';

import { showToast } from '@/app/toasts/utils/showToast';
import { apiPatch } from '@/core/api';
import { showNetworkError } from '@/core/api/helper';
import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';

import type { CampaignOrder, CampaignResource } from '@/app/campaigns/types';

export const useUpdateCampaign = (campaignId: string, successToastMessage?: string) => {
    const queryClient = useQueryClient();
    const { query } = useRouter();
    const { canUpdate: canUpdateCampaign } = useUserCapabilities(CapabilitiesTarget.Campaign);

    // Snapshot the previous value
    const previousCampaign = queryClient.getQueryData<CampaignResource>(
        QUERY_KEYS.campaign(campaignId),
    );
    const workspaceId = previousCampaign?.relationships?.workspace?.data?.id;

    const campaignsQueryKeys = QUERY_KEYS.campaigns({
        filter: query.filter as string,
        search: query.search as string,
        workspaceId: (query?.workspaceId as string) || workspaceId,
        crmCampaignsOnly: !canUpdateCampaign,
        page: query.page as string,
        order: query.order as CampaignOrder,
    });

    return useMutation({
        mutationKey: MUTATION_KEYS.updateCampaign(campaignId),
        mutationFn: async (campaign: CampaignResource) =>
            apiPatch(ENDPOINTS.PATCH.updateCampaign(campaignId), { data: campaign }),
        onMutate: async (updatedCampaign) => {
            if (!updatedCampaign || !updatedCampaign.id) {
                return;
            }
            // Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: QUERY_KEYS.campaign(updatedCampaign.id) });

            // Cancel any outgoing re-fetches (so they don't overwrite our optimistic update)
            await queryClient.cancelQueries({ queryKey: campaignsQueryKeys });

            // This could return undefined if campaigns were not previously fetched (i.e. User reloads the funnel editor)
            const previousCampaigns = queryClient.getQueryData(campaignsQueryKeys);

            // Optimistically update to the new value
            queryClient.setQueryData(QUERY_KEYS.campaign(updatedCampaign.id), updatedCampaign);

            if (previousCampaigns) {
                // Optimistically update the list of campaigns
                queryClient.setQueryData(campaignsQueryKeys, ({ campaigns, meta }) => {
                    const optimisticallyUpdatedCampaigns = campaigns?.map(
                        (campaign: CampaignResource) => {
                            if (campaign.id === campaignId) {
                                return updatedCampaign;
                            }

                            return campaign;
                        },
                    );

                    return { campaigns: optimisticallyUpdatedCampaigns, meta };
                });
            }

            // Return a context object with the snapshotted value
            return { previousCampaign, previousCampaigns };
        },
        onError: (err, updatedCampaign, context) => {
            // If the mutation fails, use the context returned from onMutate to roll back

            // If the mutation fails, return previous campaign
            queryClient.setQueryData(
                QUERY_KEYS.campaign(updatedCampaign.id),
                context?.previousCampaign,
            );

            // If the mutation fails, return previous campaigns if they existed in the first place
            if (context?.previousCampaigns) {
                // If the mutation fails, return previous campaigns
                queryClient.setQueryData(campaignsQueryKeys, context?.previousCampaigns);
            }

            showNetworkError(err);
        },
        onSuccess: () => {
            if (successToastMessage) {
                showToast({ type: 'success', message: successToastMessage });
            }
        },
        onSettled: (data, error, variables) => {
            // Always re-fetch campaign and list of campaigns after error or success to ensure we have the correct data
            queryClient.invalidateQueries({ queryKey: QUERY_KEYS.campaign(variables.id) });
            queryClient.invalidateQueries({ queryKey: ['campaigns'] });
        },
    });
};
