import { NAME } from '@/app/campaigns/constants';

import Link from 'next/link';
import { useTranslation } from 'react-i18next';

import { CapabilitiesTarget, useUserCapabilities } from '@/hooks/useUserCapabilities';
import { Tooltip } from '@/ui/components/Tooltip';

export const NoContacts = ({ link }) => {
    const { t } = useTranslation(NAME);
    const { canRead: canReadContacts } = useUserCapabilities(CapabilitiesTarget.Contacts);

    if (!canReadContacts) {
        return (
            <Tooltip content={`0 ${t('contacts')}`}>
                <span className="text-sm leading-7 text-gray-400">{t('no-contacts')}</span>
            </Tooltip>
        );
    }

    return (
        <Link href={link} className="flex items-center gap-2">
            <Tooltip content={`0 ${t('contacts')}`}>
                <span className="text-sm leading-7 text-gray-400">{t('no-contacts')}</span>
            </Tooltip>
        </Link>
    );
};
