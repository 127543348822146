import type { ApplicationName, ConnectionState, CyclrIntegrationConfig } from './types';

export const NAME = 'integrations';

export const INTEGRATION_FORM_ERRORS = {
    'google-analytics': { trackingId: 'The tracking ID should look like this UA-970334309-1' },
};
export const DEFAULT_STRING_ERROR = 'Invalid format';
export const REQUIRED_ERROR = 'Required field.';

export const MULTI_FIELD_TYPES = ['MAP', 'MAP_STATIC', 'LIST', 'MIXED', 'SELECT'];

export const FACEBOOK_CAPI_INTEGRATION_KEY = 'Meta (Facebook & Instagram)';

export const MAX_SUBSCRIBERS = 5;
export const EMAIL_SUBJECT_CHAR_LIMIT = 120;
export const EMAIL_DESCRIPTION_CHAR_LIMIT = 2500;

export const PROCESSING_CONNECTION_STATES: ConnectionState[] = [
    'connecting',
    'creatingConnection',
    'updatingData',
];

// Cyclr integrations

export const CYCLR_INTEGRATIONS_CONFIG: Record<string, CyclrIntegrationConfig | undefined> = {
    'personio-create-applicant': {
        applicationName: 'personio',
        fields: ['personioCompanyId', 'personioApiKey'],
    },
    'activecampaign-upsert-contact': {
        applicationName: 'activeCampaign',
        fields: ['activeCampaignAccountURL', 'activeCampaignApiKey'],
    },
    'recruitee-create-candidate': {
        applicationName: 'recruitee',
        fields: ['recruiteeApiKey', 'recruiteeCompanyId'],
    },
    'brevo-create-contact': {
        applicationName: 'brevo',
        fields: ['brevoApiKey'],
    },
};

// External links
export const MISSING_INTEGRATION_LINK_EN = 'https://integrations.perspectivefunnel.com/survey/';
export const MISSING_INTEGRATION_LINK_DE = 'https://integrations.perspectivefunnel.com/umfrage/';
export const MORE_ZAPS_URL = 'https://zapier.com/apps/perspective/integrations';

export const MAX_NR_OF_WEBHOOKS_PER_EVENT = 3;

export const ENDPOINTS = {
    GET: {
        integration: (integrationId: string) => `/integrations/${integrationId}`,
        connections: (integrationName: string) => `/cyclr-application/${integrationName}`,
        subscribers: (campaignId: string) => `/notifications?campaign=${campaignId}`,
        webhooks: (campaignId: string) => `/webhooks?campaign=${campaignId}`,
        integrationSchemas: (campaignId: string) => `/integrations/schemas?campaign=${campaignId}`,
        makeApiKey: '/users/me/apiKey',
    },
    POST: {
        addIntegration: '/integrations',
        addSubscriber: '/notifications',
        addWebhook: '/webhooks',
        createMakeApiKey: '/users/me/apiKey',
        createConnection: (applicationName: ApplicationName) =>
            `/cyclr-application/${applicationName}`,
        connectCyclrApplication: (applicationId: string) =>
            `/cyclr-application/${applicationId}/use`,
    },
    PATCH: {
        enableIntegration: (integrationId: string) => `/integrations/${integrationId}/enable`,
        updateIntegration: (integrationId: string) => `/integrations/${integrationId}/config`,
        updateWebhook: (webhookId: string) => `/webhooks/${webhookId}`,
    },
    DELETE: {
        removeIntegration: (integrationId: string) => `/integrations/${integrationId}`,
        removeSubscriber: (subscriberId: string, campaignId: string) =>
            `/notifications/unsubscribe/${subscriberId}/${campaignId}`,
        removeWebhook: (webhookId: string) => `/webhooks/${webhookId}`,
    },
} as const;

export const QUERY_KEYS = {
    integrations: (campaignId: string) => ['integrations', 'list', campaignId],
    connections: (campaignId: string, applicationName: string) => [
        'connections',
        campaignId,
        applicationName,
    ],
    subscribers: (campaignId: string) => ['subscribers', 'list', campaignId],
    webhooks: (campaignId: string) => ['webhooks', 'list', campaignId],
    integrationSchemas: (campaignId: string) => ['integrationSchemas', 'list', campaignId],
    makeApiKey: () => ['makeApiKey'],
} as const;

export const MUTATION_KEYS = {
    addIntegration: (campaignId: string) => ['integrations', 'add', campaignId],
    updateIntegration: (campaignId: string) => ['integrations', 'update', campaignId],
    enableIntegration: (campaignId: string) => ['integrations', 'enable', campaignId],
    removeIntegration: (campaignId: string) => ['integrations', 'remove', campaignId],
    addSubscriber: (campaignId: string) => ['subscribers', 'add', campaignId],
    removeSubscriber: (campaignId: string) => ['subscribers', 'remove', campaignId],
    addWebhook: (campaignId: string) => ['webhooks', 'add', campaignId],
    updateWebhook: (campaignId: string) => ['webhooks', 'update', campaignId],
    removeWebhook: (campaignId: string) => ['webhooks', 'remove', campaignId],
    createMakeApiKey: () => ['makeApiKey'],
    connectCyclrApplication: (campaignId: string) => ['connectCyclrApplication', campaignId],
} as const;

export const ROUTES = {
    integrations: (campaignId: string) => `/funnel/${campaignId}/integrations`,
} as const;
