import { TRACKING_EVENTS } from '@/core/tracking/constants';

import debounce from 'lodash/debounce';
import { useRouter } from 'next/router';
import { useMemo } from 'react';
import { MobileOnlyView } from 'react-device-detect';

import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { track } from '@/core/tracking';
import { cn } from '@/utils/cn';
import { useUserCapabilities, CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';

import DesktopActionButtons from './DesktopActionButtons';
import { Filter } from './Filter';
import { GlobalSearchToggle } from './GlobalSearchToggle';
import MobileSearch from './MobileSearch';
import { CampaignFilter } from '../../../types';

const TopBar = () => {
    const router = useRouter();
    const { canUpdate } = useUserCapabilities(CapabilitiesTarget.Campaign);
    const search = router?.query?.search;
    const { activeWorkspace, hasCustomWorkspaces } = useWorkspaces();
    const workspaceName = activeWorkspace?.attributes?.name;

    const triggerSearch = useMemo(() => {
        return debounce((value: string) => {
            router.replace(
                {
                    query: {
                        id: router?.query?.id,
                        filter: CampaignFilter.search,
                        search: value,
                        workspaceId: router?.query?.id,
                    },
                },
                undefined,
                { shallow: true },
            );

            track(TRACKING_EVENTS.campaign.overview.search.used, { search: value });
        }, 1000);
    }, [router]);

    const handleSearch = async (value: string) => {
        if (!value || value.length === 0) {
            router.replace(
                {
                    query: {
                        id: router?.query?.id,
                    },
                },
                undefined,
                { shallow: true },
            );

            return;
        }

        triggerSearch(value);
    };

    const handleMobileSearchChange = async (values: { name: string }) => {
        handleSearch(values.name);
    };

    return (
        <div
            className={cn(
                'relative mt-6 flex flex-col items-start gap-4 transition-all duration-300 ease-in-out md:mt-10 lg:mt-14',
                {
                    'mb-16 lg:mb-18': hasCustomWorkspaces && !!search,
                    'mb-8 lg:mb-12': !hasCustomWorkspaces || !search,
                },
            )}
        >
            <div className="z-10 flex w-full items-center justify-between gap-1">
                {!canUpdate ? (
                    <h1 className="w-full text-xl font-semibold sm:text-3xl">{workspaceName}</h1>
                ) : (
                    <Filter filterAllLabel={undefined} options={undefined} />
                )}

                <DesktopActionButtons handleSearch={handleSearch} />
            </div>

            <MobileOnlyView className="relative z-10 flex w-full items-center justify-center">
                <MobileSearch onChange={handleMobileSearchChange} />
            </MobileOnlyView>

            {search && <GlobalSearchToggle show={!!search} />}
        </div>
    );
};

export default TopBar;
