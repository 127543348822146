import {
    CAMPAIGN_LIST_DISPLAY_MODE_KEY,
    CREATE_FUNNEL_BUTTON_ID,
    NAME,
} from '@/app/campaigns/constants';

import { ListBulletIcon, Squares2X2Icon } from '@heroicons/react/24/outline';
import { AnimatePresence, motion, type Variants } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { BrowserView } from 'react-device-detect';

import { getDisplayMode, setDisplayMode, setOrder } from '@/app/campaigns/models/overview';
import { CampaignOrder, OverviewDisplayMode } from '@/app/campaigns/types';
import { useWorkspaces } from '@/app/workspaces/hooks/useWorkspaces';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';
import { ExpandableSearch } from '@/ui/components/ExpandableSearch';
import { Tooltip } from '@/ui/components/Tooltip';
import { EMPTY_STRING } from '@/utils/empty';
import { useUserCapabilities, CapabilitiesTarget } from '@/utils/hooks/useUserCapabilities';
import { tw } from '@/utils/tw';

import type { FormEvent } from 'react';

interface Props {
    handleSearch: (value: string) => void;
}

const toggleIconVariants: Variants = {
    hidden: {
        opacity: 0,
        scale: 0.6,
        transition: { type: 'easeOut', duration: 0.1 },
    },
    visible: {
        opacity: 1,
        scale: 1,
        transition: { type: 'easeOut', duration: 0.1 },
    },
};

const sharedMotionProps = {
    variants: toggleIconVariants,
    initial: 'hidden',
    animate: 'visible',
    exit: 'hidden',
};

const buttonClass = tw`bump hidden size-11 rounded-lg text-center text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-800 sm:block`;

const DesktopActionButtons = ({ handleSearch }: Props) => {
    const { t } = useTranslation(NAME);
    const dispatch = useAppDispatch();
    const { canCreate } = useUserCapabilities(CapabilitiesTarget.Campaign);
    const { activeWorkspace } = useWorkspaces();
    const displayMode = useAppSelector(getDisplayMode);
    const router = useRouter();
    const searchTerm = router?.query?.search || EMPTY_STRING;

    const handleSearchChange = (event: FormEvent<HTMLInputElement>) => {
        const value = event?.currentTarget?.value;

        handleSearch(value);
    };

    const toggleDisplayMode = () => {
        const newDisplayMode =
            displayMode === OverviewDisplayMode.grid
                ? OverviewDisplayMode.list
                : OverviewDisplayMode.grid;

        localStorage.setItem(CAMPAIGN_LIST_DISPLAY_MODE_KEY, newDisplayMode);

        dispatch(setOrder(CampaignOrder.isFavReverse));
        dispatch(setDisplayMode(newDisplayMode));
    };

    const handleSearchClose = async () => {
        const activeWorkspaceId = router?.query?.id || activeWorkspace?.id;
        router.replace(
            {
                pathname: `/workspaces/${activeWorkspaceId}`,
                query: {},
            },
            undefined,
            { shallow: true },
        );
    };

    return (
        <div className="flex items-center gap-4">
            <BrowserView>
                <ExpandableSearch
                    placeholder={t('common:search')}
                    onClose={handleSearchClose}
                    expanded={!!searchTerm}
                    input={{
                        defaultValue: searchTerm as string,
                        onChange: handleSearchChange,
                    }}
                />
            </BrowserView>

            <Tooltip
                content={displayMode === 'grid' ? t('list-view') : t('grid-view')}
                placement="bottom"
            >
                <button onClick={toggleDisplayMode} className={buttonClass}>
                    <AnimatePresence mode="popLayout" initial={false}>
                        {displayMode === OverviewDisplayMode.grid ? (
                            <motion.div key="list" {...sharedMotionProps}>
                                <ListBulletIcon className="inline size-6" />
                            </motion.div>
                        ) : (
                            <motion.div key="grid" {...sharedMotionProps}>
                                <Squares2X2Icon className="inline size-6" />
                            </motion.div>
                        )}
                    </AnimatePresence>
                </button>
            </Tooltip>

            {canCreate && (
                <Link href="/new" className="ml-4 hidden shrink-0 md:inline-block">
                    <button
                        id={CREATE_FUNNEL_BUTTON_ID}
                        className="bump rounded-lg bg-blue-500 px-5 py-3 text-center text-sm text-white shadow-button-inset hover:bg-blue-600"
                    >
                        {t('new-funnel')}
                    </button>
                </Link>
            )}
        </div>
    );
};

export default DesktopActionButtons;
