import dynamic from 'next/dynamic';

import { getPreloadedCampaigns, preloadCampaign } from '@/app/campaigns/models/campaigns';
import { useAppDispatch, useAppSelector } from '@/core/redux/hooks';

const DraftEditor = dynamic(() => import('@/app/editor/blocks/components/Text/DraftEditor'), {
    ssr: false,
});
const TipTapBlock = dynamic(
    () =>
        import('@/app/editor/blocks/components/Text/TipTapBlock/TipTapBlock').then(
            (mod) => mod.TipTapBlock,
        ),
    { ssr: false },
);
const ArtBoard = dynamic(() => import('@/app/editor/editor/components/ArtBoard'), { ssr: false });
const Sidebar = dynamic(() => import('@/app/editor/editor/components/Sidebar'), { ssr: false });

export const usePreloadEditor = (campaignId: string) => {
    const dispatch = useAppDispatch();
    const preloadedCampaigns = useAppSelector(getPreloadedCampaigns);

    // preload heavy Editor UI components (https://github.com/vercel/next.js/discussions/13738#discussioncomment-332105)
    const preloadUI = () => {
        // @ts-ignore
        DraftEditor.render.preload();
        // @ts-ignore
        ArtBoard.render.preload();
        // @ts-ignore
        Sidebar.render.preload();
        // @ts-ignore
        TipTapBlock.render.preload();
    };

    return () => {
        preloadUI();

        if (!preloadedCampaigns.includes(campaignId)) {
            dispatch(preloadCampaign(campaignId));
        }
    };
};
