import { LinkIcon, CheckIcon } from '@heroicons/react/16/solid';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

import { Tooltip } from '@/ui/components/Tooltip';

import type { Placement } from '@floating-ui/react';
import type { SVGProps, ReactNode } from 'react';

interface Props {
    link: string;
    tooltipPlacement?: Placement;
    tooltip?: string;
    icon?: (props: SVGProps<SVGSVGElement>) => ReactNode;
    onCopy?: () => void;
}

const variants = {
    hidden: { opacity: 0, scale: 0.6, transition: { type: 'spring', duration: 0.15, bounce: 0 } },
    visible: { opacity: 1, scale: 1, transition: { type: 'spring', duration: 0.15, bounce: 0 } },
};

export const CopyLinkButton = ({ link, tooltipPlacement, tooltip, icon, onCopy }: Props) => {
    const { t } = useTranslation('common');
    const [isCopied, setIsCopied] = useState(false);

    const handleCopy = () => {
        onCopy?.();

        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000);
    };

    const Icon = icon ?? LinkIcon;

    return (
        <Tooltip
            content={isCopied ? t('copied') : (tooltip ?? t('copy-to-clipboard'))}
            placement={tooltipPlacement}
        >
            <div>
                <CopyToClipboard text={link} onCopy={handleCopy}>
                    <div className="bump flex size-8 cursor-pointer items-center rounded-md bg-gray-100 fill-current px-2 text-gray-500 transition-colors hover:text-gray-800">
                        <AnimatePresence mode="wait" initial={false}>
                            {isCopied ? (
                                <motion.span
                                    key="checkmark"
                                    variants={variants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                >
                                    <CheckIcon className="size-4" />
                                </motion.span>
                            ) : (
                                <motion.span
                                    key="copy"
                                    variants={variants}
                                    initial="hidden"
                                    animate="visible"
                                    exit="hidden"
                                >
                                    <Icon className="size-4" />
                                </motion.span>
                            )}
                        </AnimatePresence>
                    </div>
                </CopyToClipboard>
            </div>
        </Tooltip>
    );
};
