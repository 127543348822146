import { NAME } from '@/app/campaigns/constants';

import Link from 'next/link';
import { useTranslation } from 'next-i18next';

import { getCampaignConversionRate, getCampaignIsArchived } from '@/app/campaigns/helpers';
import { Tooltip } from '@/ui/components/Tooltip';
import { cn } from '@/utils/cn';

import type { CampaignResource } from '@/app/campaigns/types';

export interface Props {
    campaign: CampaignResource;
    link: string;
}

const Rate = ({ rate }: { rate: number }) => {
    const { t } = useTranslation(NAME);

    return (
        <Tooltip content={t('conversion-rate')}>
            <div className={cn('flex-1 text-sm text-gray-400', { 'text-gray-900': rate > 0 })}>
                {rate}%
            </div>
        </Tooltip>
    );
};

export const ConversionRate = ({ campaign, link }: Props) => {
    const isArchived = getCampaignIsArchived(campaign);
    const conversionRate = getCampaignConversionRate(campaign);

    if (isArchived) {
        return <Rate rate={conversionRate} />;
    }

    return (
        <Link href={link}>
            <div className="w-full">
                <Rate rate={conversionRate} />
            </div>
        </Link>
    );
};
